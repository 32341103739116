import React, { useContext, useEffect, useState } from "react";
import { onmaxContext } from "../../OnmaxContext";
import Web3 from "web3";
import Spinner from "../Comman/Spinner";
import Copy from "../Comman/Copy";
import Tokens from "../Comman/Tokens.json";
import OnmaxSpinner from "../ContentLoader/OnmaxSpinner";

export default function Flexibale() {
  const {
    getEtherContractFlexibale,
    toastSuccess,
    toastError,
    // getBalanceBNB,
    giveAllowance,
    checkAllowance,
    connAddress,
    formatAddress,
    getWeb3TokenContract,
    getWeb3ContractFlexibale,
    getTokenBalance,
  } = useContext(onmaxContext);
  const [selectedToken, setSelectedToken] = useState(null);
  const [stakeModal, setStakeModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [selectedTokenBalance, setSelectedTokenBalance] = useState(0);

  const [tokens, setTokens] = useState(null);
  const [dataSpinner, setDataSpinner] = useState(true);

  const setTokenBalance = async () => {
    const bal = await getTokenBalance(selectedToken.address);
    setSelectedTokenBalance(bal);
  };

  useEffect(() => {
    if (selectedToken && connAddress) {
      setTokenBalance();
    } else {
      setSelectedTokenBalance(0);
    }
  }, [selectedToken]);

  const getApy = async () => {
    try {
      if (Tokens.length) {
        setDataSpinner(true);
        let tokenscpy = [...Tokens];
        const contract = await getWeb3ContractFlexibale();
        const apyper = await contract.methods.getAllLockTimePer().call();
        for (let i = 0; i < tokenscpy.length; i++) {
          let defaultApy = [{ month: 1, apyper: 0, status: false }];
          for (let j = 0; j < apyper.length; j++) {
            if (tokenscpy[i].address.toLowerCase() === apyper[j].token.toLowerCase()) {
              defaultApy[0].apyper = Number(Web3.utils.fromWei(apyper[j].apy, "ether"));
              defaultApy[0].status = apyper[j].status;
            }
          }
          tokenscpy[i].apy = [...defaultApy];
          tokenscpy[i].balanceLoader = true;
          tokenscpy[i].totalstakeLoader = true;
        }
        setTokens([...tokenscpy]);
        setDataSpinner(false);
        getTokenLockValue();
      }
    } catch (err) {
      console.log("error in getApy : ", err);
    }
  };

  const getTokenLockValue = async () => {
    try {
      if (Tokens.length) {
        let tokenscpy = [...Tokens];
        const contract = await getWeb3ContractFlexibale();
        for (let i = 0; i < tokenscpy.length; i++) {
          const stakewei = await contract.methods.getTotalStakingOfToken(tokenscpy[i].address).call();
          const totalstake = Number(await Web3.utils.fromWei(stakewei, "ether")).toFixed(2);
          tokenscpy[i].totalstake = totalstake;
          tokenscpy[i].totalstakeLoader = false;
        }
        setTokens([...tokenscpy]);
      }
    } catch (err) {
      console.log("error in getTokenLockValue : ", err);
    }
  };

  useEffect(() => {
    getApy();
  }, [connAddress]);

  const setTokenData = (data) => {
    setSelectedToken(data);
    setStakeModal(true);
  };

  const closeStakeModal = () => {
    setSelectedToken(null);
    setAmount(0);
    setSpinner(false);
    setStakeModal(false);
  };

  const stake = async () => {
    try {
      setSpinner(true);
      if (amount > 0) {
        if (amount <= Number(selectedTokenBalance)) {
          let temp = true;
          const contract = await getEtherContractFlexibale();
          const amtWei = Web3.utils.toWei(amount, "ether");
          const res = await checkAllowance(selectedToken?.address, connAddress, process.env.REACT_APP_CONTRACT_FLEXIBALE, amount);
          if (!res) {
            const data = await giveAllowance(selectedToken?.address, process.env.REACT_APP_CONTRACT_FLEXIBALE);
            if (!data) {
              temp = false;
              toastError("Something went wrong.");
              setSpinner(false);
            }
          }
          if (temp) {
            const tx = await contract.stake(selectedToken?.address, amtWei);
            let recipt = await tx.wait();
            if (recipt?.transactionHash) {
              toastSuccess("Farm successfully.");
              // getBalanceBNB();
              closeStakeModal();
              setSpinner(false);
            }
          }
        } else {
          toastError("Insufficient funds.");
          setSpinner(false);
        }
      } else {
        toastError("Amount must be greaterthan 0.");
        setSpinner(false);
      }
    } catch (err) {
      console.log("error in stake : ", err);
      toastError("Something went wrong.");
      setSpinner(false);
    }
  };

  return (
    <>
      {/* Grid */}
      {dataSpinner ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-3">
          {tokens?.length ? (
            tokens?.map((item, index) => {
              return (
                <article key={index}>
                  <div className="block  border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
                    <figure className="relative">
                      <div className=" sm:pr-4 lg:pr-8">
                        <div className="mt-3 flex">
                          <figure className="mr-4 shrink-0">
                            {item.icon ? (
                              <a href="" className="relative block">
                                <img src={item.icon} width="60" alt="avatar" className="rounded-2lg" loading="lazy" />
                              </a>
                            ) : (
                              ""
                            )}
                          </figure>
                          <div>
                            <div className="flex items-center whitespace-nowrap">
                              <span className="text-lg font-medium leading-tight tracking-tight text-green flex">
                                {item.totalstakeLoader ? <OnmaxSpinner /> : item?.totalstake > 0 ? item?.totalstake : 0} {item.symbol}
                              </span>
                            </div>
                            <span className="text-sm text-jacarta-400 dark:text-jacarta-300 flex">
                              {formatAddress(item.address)} <Copy text={item.address}></Copy>
                            </span>
                          </div>
                        </div>
                      </div>
                    </figure>
                    <div className="mt-7 flex items-center justify-between">
                      <span className="font-display text-base text-jacarta-700 hover:text-accent dark:text-white">{item.name}</span>
                    </div>
                    <div className="mt-2 text-sm">
                      <span className="mr-1 text-jacarta-700 dark:text-jacarta-200">
                        MAX APY : {item.apyLoader ? "..." : item.apy?.length ? item.apy[item.apy.length - 1]?.month : ""} month{" "}
                        {item.apy?.length ? item.apy[item.apy.length - 1]?.apyper : ""} %
                      </span>
                      {/* <span className="text-jacarta-500 dark:text-jacarta-300">2/8</span> */}
                    </div>
                    {/* <div className="mt-2 text-sm">
                      <span className="mr-1 text-jacarta-700 dark:text-jacarta-200">
                        Total Locked Value :{" "}
                        {item.totalstakeLoader ? "..." : item?.totalstake > 0 ? item?.totalstake : 0}
                      </span>
                    </div> */}
                    {item.apy[item.apy.length - 1]?.status ? (
                      <div className="mt-8 flex items-center justify-between">
                        <button
                          className="inline-block w-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark cursor-pointer"
                          onClick={() => setTokenData(item)}
                          disabled={item?.apy?.length ? false : true}
                        >
                          Farm
                        </button>
                      </div>
                    ) : (
                      <div className="mt-8 flex items-center justify-between">
                        <button
                          className="inline-block w-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark cursor-pointer"
                          onClick={() => setTokenData(item)}
                          disabled
                        >
                          Not Available
                        </button>
                      </div>
                    )}
                  </div>
                </article>
              );
            })
          ) : (
            <>
              <h2 className="text-center text-white"></h2>
              <h2 className="text-center text-white">No data found.</h2>
              <h2 className="text-center text-white"></h2>
            </>
          )}
        </div>
      )}
      <div
        className={`modal fade ${
          stakeModal ? "show" : ""
        } block border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700`}
        id="placeBidModal"
        tabIndex={-1}
        aria-labelledby="placeBidLabel"
        style={stakeModal ? { display: "block", overflowY: "hidden" } : { display: "none", overflowY: "hidden" }}
      >
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Farm
              </h5>
              <button type="button" className="btn-close" onClick={closeStakeModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="h-6 w-6 fill-jacarta-700 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>
            {/* Body */}
            <div className="modal-body p-2">
              <div className="mb-2 flex items-center justify-between">
                <span className="font-display font-semibold text-jacarta-700 dark:text-white">Amount</span>
              </div>
              <div className="relative mb-2 flex items-center overflow-hidden">
                <input
                  type="text"
                  className="h-12 w-full flex-[3] focus:ring-accent text-white shadow-accent-volume"
                  placeholder="0"
                  value={amount}
                  onChange={(e) => {
                    if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) setAmount(Number(e.target.value));
                  }}
                />
                <div className="flex flex-1 justify-center self-stretch border-l border-jacarta-100 bg-jacarta-50 shadow-accent-volume bg-accent hover:bg-accent-dark text-white cursor-pointer max">
                  <span className="self-center px-2 " onClick={() => setAmount(selectedTokenBalance)}>
                    Max
                  </span>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-left">
                  <span className="text-white">Balance:</span>
                </div>
                <div className="text-right">
                  <span className="text-white">
                    {selectedTokenBalance ? selectedTokenBalance : 0} {selectedToken?.symbol}
                  </span>
                </div>
              </div>
              <div className="dropdown my-1 cursor-pointer">
                <div className="sm:col-span-3 w-full">
                  <div className="flex justify-between">
                    <div className="text-left">
                      <span className="text-white">Lock Time : </span>
                    </div>
                    <div className="text-right">
                      <span className="text-white">
                        {selectedToken?.apy ? selectedToken?.apy[0]?.month : 1} month (
                        {selectedToken?.apy ? selectedToken?.apy[0]?.apyper : 1}
                        %)
                      </span>
                    </div>
                  </div>
                  {/* <p className="dark:text-jacarta-300">
                    Once you've set up your wallet of choice, connect it to OpenSeaby clicking the NFT Marketplacein the top right corner.
                  </p> */}
                </div>
              </div>
            </div>
            {/* end body */}
            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                {spinner ? (
                  <Spinner />
                ) : (
                  <button
                    type="button"
                    className="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                    onClick={() => stake()}
                    // disabled={selectedTokenBalance > 0 ? false : true}
                  >
                    Farm
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class={stakeModal ? "modal-backdrop fade show" : ""}></div>
    </>
  );
}
