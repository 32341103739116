import React, { useState } from "react";
import Fixed from "./Fixed";
import Flexibale from "./Flexibale";

export default function Hero() {
  const [tab, setTab] = useState("fixed");
  return (
    <>
      <section className="pb-24" style={{ marginTop: "50px" }}>
        <div style={{ marginTop: "100px", marginBottom: "40px" }}>
          <img src="assets/img/farming.jpg" style={{ width: "100%", height: "auto" }} />
        </div>
        <div className="container">
          <h2 className="mb-8 text-center font-display text-3xl text-jacarta-700 dark:text-white">Onmax Farming</h2>
          {/* Filter */}
          <div className="mb-8 flex flex-wrap items-center justify-center">
            <ul className="flex flex-wrap items-center">
              <li className="my-1 mr-2.5" onClick={() => setTab("fixed")}>
                <button
                  className={`${
                    tab === "fixed" ? "tab-active" : ""
                  } group flex h-9 items-center rounded-lg border custom-btn border-jacarta-100 bg-white px-4 font-display text-sm font-semibold text-jacarta-500 transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-900 dark:text-white dark:hover:border-transparent dark:hover:bg-accent dark:hover:text-white`}
                >
                  <span>Fixed</span>
                </button>
              </li>
              <li className="my-1 mr-2.5" onClick={() => setTab("flexibale")}>
                <button
                  className={`${
                    tab === "flexibale" ? "tab-active" : ""
                  } group flex h-9 items-center custom-btn rounded-lg border border-jacarta-100 bg-white px-4 font-display text-sm font-semibold text-jacarta-500 transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-900 dark:text-white dark:hover:border-transparent dark:hover:bg-accent dark:hover:text-white`}
                >
                  <span>Flexibale</span>
                </button>
              </li>
            </ul>
          </div>
          {/* Grid */}
          {tab === "fixed" ? <Fixed tab={tab} /> : tab === "flexibale" ? <Flexibale tab={tab} /> : ""}
        </div>
      </section>
      <div
        className="modal fade"
        id="placeBidModal"
        tabIndex={-1}
        aria-labelledby="placeBidLabel"
        aria-modal="true"
        role="dialog"
        style={{ display: "none", paddingLeft: "0px" }}
      >
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Place a bid
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="h-6 w-6 fill-jacarta-700 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>
            {/* Body */}
            <div className="modal-body p-6">
              <div className="mb-2 flex items-center justify-between">
                <span className="font-display text-sm font-semibold text-jacarta-700 dark:text-white">Price</span>
              </div>
              <div className="relative mb-2 flex items-center overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600">
                <input
                  type="text"
                  className="h-12 w-full flex-[3] border-0 focus:ring-inset focus:ring-accent"
                  placeholder="Amount"
                  defaultValue="0.05"
                />
                <div className="flex flex-1 justify-center self-stretch border-l border-jacarta-100 bg-jacarta-50">
                  <span className="self-center px-2 text-sm">Max</span>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-left">
                  <span className="text-sm dark:text-jacarta-400">Balance:</span>
                </div>
                <div className="text-right">
                  <span className="text-sm dark:text-jacarta-400">0.0000 WETH</span>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-left">
                  <span className="text-sm dark:text-jacarta-400">Balance:</span>
                </div>
                <div className="text-right">
                  <span className="text-sm dark:text-jacarta-400">0.0000 WETH</span>
                </div>
              </div>
            </div>
            {/* end body */}
            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  type="button"
                  className="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                >
                  Stake
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
