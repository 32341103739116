import React, { useContext, useEffect, useState } from "react";
import { onmaxContext } from "../../OnmaxContext";
import Stakings from "./Components/Stakings";
import TotalStakings from "./Components/TotalStakings";
import Withdrawals from "./Components/Withdrawals";
import Unstake from "./Components/Unstake";

export default function DashboardNav() {
  const [mainTab, setMainTab] = useState("stakes");

  return (
    <>
      <section className="relative py-24 pt-0">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img src="assets/img/gradient_light.jpg" alt="gradient" className="h-full w-full" />
        </picture>
        <div className="container">
          {/* Tabs Nav */}
          <ul
            className="nav nav-tabs scrollbar-custom mb-12 flex items-center justify-start overflow-x-auto overflow-y-hidden border-b border-jacarta-100 pb-px dark:border-jacarta-600 md:justify-center"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className={`${
                  mainTab === "stakes" ? "active" : ""
                } nav-link relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white`}
                onClick={() => setMainTab("stakes")}
                type="button"
              >
                <span className="font-display text-base font-medium">Farm</span>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`${
                  mainTab === "unstakes" ? "active" : ""
                } nav-link relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white`}
                onClick={() => setMainTab("unstakes")}
                type="button"
              >
                <span className="font-display text-base font-medium">Unfarm</span>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`${
                  mainTab === "totalstakings" ? "active" : ""
                } nav-link relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white`}
                onClick={() => setMainTab("totalstakings")}
                type="button"
              >
                <span className="font-display text-base font-medium">Total Farming</span>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`${
                  mainTab === "withdrawals" ? "active" : ""
                } nav-link relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white`}
                onClick={() => setMainTab("withdrawals")}
                type="button"
              >
                <span className="font-display text-base font-medium">Harvest</span>
              </button>
            </li>
          </ul>
          {mainTab === "stakes" ? (
            <Stakings mainTab={mainTab} />
          ) : mainTab === "unstakes" ? (
            <Unstake />
          ) : mainTab === "totalstakings" ? (
            <TotalStakings mainTab={mainTab} />
          ) : mainTab === "withdrawals" ? (
            <Withdrawals mainTab={mainTab} />
          ) : (
            ""
          )}
        </div>
      </section>
    </>
  );
}
