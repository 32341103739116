import React from "react";

const OnmaxSpinner = () => {
  return (
    <img
      src="assets/img/token/onmx.png"
      style={{ height: "21px", width: "21px" }}
      alt="collection avatar"
      className="animated-image-container animated-image"
    />
  );
};

export default OnmaxSpinner;
