import React from "react";
import DashboardHero from "./DashboardHero";
import DashboardNav from "./DashboardNav";
import Header2 from "../Comman/Header2";
import Footer from "../Comman/Footer";

export default function Dashboard() {
  return (
    <>
      <Header2 />
      <DashboardHero />
      <DashboardNav />
      <Footer />
    </>
  );
}
