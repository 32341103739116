import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Hero from './Hero'

export default function Index() {
    return (
        <>
            <Header />
            <Hero />
            <Footer />
        </>
    )
}
