import React from "react";
import Copy from "./Copy";

export default function Footer() {
  return (
    <>
      <footer className="page-footer bg-white dark:bg-jacarta-900">
        <div className="container">
          <div className=" flex content-center w-full">
            <h3 className="mb-6 font-display text-sm text-jacarta-700 dark:text-white text-center w-full flex content-center w-full">
              <div className="flex mx-4">
                <a
                  href={process.env.REACT_APP_EXPLORER_URL + "address/" + process.env.REACT_APP_CONTRACT_FIXED}
                  target="_blank"
                >
                  Contract Fixed
                </a>
                &nbsp;&nbsp;
                <Copy text={process.env.REACT_APP_CONTRACT_FIXED} />
              </div>

              <div className="flex mx-4">
                <a
                  href={process.env.REACT_APP_EXPLORER_URL + "address/" + process.env.REACT_APP_CONTRACT_FLEXIBALE}
                  target="_blank"
                >
                  Contract flexible
                </a>
                &nbsp;&nbsp;
                <Copy text={process.env.REACT_APP_CONTRACT_FLEXIBALE} />
              </div>
            </h3>
          </div>
        </div>
      </footer>
    </>
  );
}
