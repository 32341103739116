import React, { useContext } from "react";
import { onmaxContext } from "../../OnmaxContext";

export default function DashboardHero() {
  const { connAddress, joiningDate } = useContext(onmaxContext);
  return (
    <>
      {/* <div className="relative">
                <img src="assets/img/user/banner.jpg" alt="banner" className="h-[18.75rem] object-cover" />
            </div> */}
      <section className="relative bg-light-base pb-12 pt-4 dark:bg-jacarta-800" style={{ marginTop: "200px" }}>
        {/* Avatar */}
        <div className="absolute left-1/2 top-0 z-10 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center">
          <figure className="relative">
            <div className="rounded-xl border-[5px] border-white custom-btn">
              <img
                src="assets/img/token/onmx.png"
                alt="collection avatar"
                className="animated-image-container animated-image"
              />
            </div>
            {/* <div
              className="absolute -right-3 bottom-0 flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
              data-tippy-content="Verified Collection"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="h-[.875rem] w-[.875rem] fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
              </svg>
            </div> */}
          </figure>
        </div>
        <div className="container pt-20">
          <div className="text-center">
            <div className="mb-8 inline-flex items-center custom-btn justify-center border border-jacarta-100 bg-white py-1.5 px-4 dark:border-jacarta-600 dark:bg-jacarta-700">
              <button
                className="js-copy-clipboard max-w-[10rem] select-none overflow-hidden text-ellipsis whitespace-nowrap dark:text-jacarta-200"
                data-tippy-content="Copy"
              >
                <span>{connAddress}</span>
              </button>
            </div>
            <br />
            <span className="text-jacarta-400">{joiningDate}</span>
          </div>
        </div>
      </section>
    </>
  );
}
